import React, { useRef } from "react"
import { BsFillTriangleFill } from "@react-icons/all-files/bs/BsFillTriangleFill"
import { accordionItem } from "./accordion.module.css"

const AccordionItem = ({ faq, active, onToggle }) => {
  const { question, answer } = faq

  const contentEl = useRef()

  return (
    <li className={accordionItem}>
      <button
        className="flex items-center w-full py-4 text-2xl text-left border-0 cursor-pointer"
        onClick={onToggle}
      >
        <BsFillTriangleFill
          className={`w-6 h-6 text-primary-500 custom-marker transition duration-300 mr-4 shrink-0 ${
            active ? "rotate-[150deg] text-primary-300" : "rotate-90"
          }`}
          aria-hidden="true"
        />
        <h2>{question}</h2>
      </button>
      <div
        ref={contentEl}
        className="h-0 overflow-hidden duration-300"
        style={
          active
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="py-4 pl-12 text-lg">{answer}</div>
      </div>
    </li>
  )
}

export default AccordionItem

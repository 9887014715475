// thanks, Ibadehin Mojeed: https://github.com/Ibaslogic/react-accordion-menu
import React, { useState } from "react"
import AccordionItem from "./accordion-item"

const Accordion = ({ faqs }) => {
  const [clicked, setClicked] = useState("0")

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0")
    }
    setClicked(index)
  }

  return (
    <ul className="list-none out-wrapper">
      {faqs.map((faq, index) => (
        <AccordionItem
          key={`${index}${faq.question}`}
          onToggle={() => handleToggle(index)}
          active={clicked === index}
          faq={faq}
        />
      ))}
    </ul>
  )
}

export default Accordion

import * as React from "react"
import { Hero } from "../components/hero"
import { RenderMDX } from "../components/render-mdx"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Accordion from "../components/accordion/accordion"

interface InstructionsProps {
  pageContext: any
  data: any
}

export default function FAQPage({ pageContext, data }: InstructionsProps) {
  const { i18n } = pageContext
  const legal = data.legal.nodes[0]
  const safe = data.safe.nodes[0]
  const classes = data.classes.nodes[0]
  const important = data.important.nodes[0]

  function defaultLayout(markupLeft, markupRight) {
    return (
      <div className="flex flex-col sm:flex-row">
        <div className="mb-4 mr-8">{markupLeft}</div>
        {markupRight}
      </div>
    )
  }

  const questions = {
    general: [
      {
        question: i18n.faq.q1.title,
        answer: defaultLayout(
          <>
            <p>{i18n.faq.q1.p1}</p>
            <p>{i18n.faq.q1.p2}</p>
          </>,
          <StaticImage
            src="../assets/images/faq/slackline.jpg"
            alt=""
            className="shrink-0"
          />
        ),
      },
      {
        question: i18n.faq.q2.title,
        answer: (
          <div className="">
            <p>{i18n.faq.q2.p1}</p>
            <p>{i18n.faq.q2.p2}</p>
          </div>
        ),
      },
      {
        question: i18n.faq.q3.title,
        answer: defaultLayout(
          <>
            <p>{i18n.faq.q3.p1}</p>
            <p>{i18n.faq.q3.p2}</p>
            <p>{i18n.faq.q3.p3}</p>
          </>,
          <StaticImage
            src="../assets/images/faq/avantages.jpg"
            alt=""
            className="shrink-0"
          />
        ),
      },
      {
        question: legal.frontmatter.title,
        answer: RenderMDX(legal.body),
      },
      {
        question: safe.frontmatter.title,
        answer: RenderMDX(safe.body),
      },
      {
        question: classes.frontmatter.title,
        answer: defaultLayout(
          RenderMDX(classes.body),
          <StaticImage
            src="../assets/images/faq/service_cours.jpg"
            alt=""
            className="shrink-0"
          />
        ),
      },
    ],
    technical: [
      {
        question: important.frontmatter.title,
        answer: RenderMDX(important.body),
      },
      {
        question: i18n.faq.q4.title,
        answer: (
          <>
            <p> {i18n.faq.q4.p1}</p>
            <h3>Trickline</h3>
            {defaultLayout(
              <p dangerouslySetInnerHTML={{ __html: i18n.faq.q4.p2 }}></p>,
              <StaticImage
                src="../assets/images/faq/trickline.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>Rodeoline</h3>
            {defaultLayout(
              <p dangerouslySetInnerHTML={{ __html: i18n.faq.q4.p3 }}></p>,
              <StaticImage
                src="../assets/images/faq/rodeoline.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>Longline</h3>
            {defaultLayout(
              <p dangerouslySetInnerHTML={{ __html: i18n.faq.q4.p4 }}></p>,
              <StaticImage
                src="../assets/images/faq/longline.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>Highline</h3>
            {defaultLayout(
              <p dangerouslySetInnerHTML={{ __html: i18n.faq.q4.p5 }}></p>,
              <StaticImage
                src="../assets/images/faq/highline.jpg"
                alt=""
                className="shrink-0"
              />
            )}
          </>
        ),
      },
      {
        question: i18n.faq.q5.title,
        answer: (
          <>
            <p>{i18n.faq.q5.p1}</p>
            <h3>{i18n.faq.q5.p2}</h3>
            {defaultLayout(
              <p dangerouslySetInnerHTML={{ __html: i18n.faq.q4.p3 }}></p>,
              <StaticImage
                src="../assets/images/faq/mousquetons.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q5.p4}</h3>
            {defaultLayout(
              <p>{i18n.faq.q5.p5}</p>,
              <StaticImage
                src="../assets/images/faq/cliquet.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q5.p6}</h3>
            {defaultLayout(
              <p>{i18n.faq.q5.p7}</p>,
              <StaticImage
                src="../assets/images/faq/poulies.jpg"
                alt=""
                className="shrink-0"
              />
            )}
          </>
        ),
      },
      {
        question: i18n.faq.q6.title,
        answer: (
          <>
            <h3>{i18n.faq.q6.p1}</h3>
            {defaultLayout(
              <>
                <p>{i18n.faq.q6.p2}</p>
                <a
                  rel="noopener"
                  href="https://www.youtube.com/watch?v=dKxvLbTRaJI"
                  target="_blank"
                >
                  {i18n.faq.q6.p3}
                </a>
              </>,
              <StaticImage
                src="../assets/images/faq/slackline.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q6.p4}</h3>
            {defaultLayout(
              <p>{i18n.faq.q6.p5}</p>,
              <StaticImage
                src="../assets/images/faq/colonnes.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q6.p6}</h3>
            {defaultLayout(
              <p>{i18n.faq.q6.p7}</p>,
              <StaticImage
                src="../assets/images/faq/post.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q6.p8}</h3>
            {defaultLayout(
              <>
                <p>{i18n.faq.q6.p9}</p>
                <a
                  rel="noopener"
                  href="https://www.youtube.com/watch?v=A7qjKR5lRu4"
                  target="_blank"
                >
                  {i18n.faq.q6.p10}
                </a>
              </>,
              <StaticImage
                src="../assets/images/faq/pieux.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q6.p11}</h3>
            {defaultLayout(
              <p>{i18n.faq.q6.p12}</p>,
              <StaticImage
                src="../assets/images/faq/gymnase.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q6.p13}</h3>
            {defaultLayout(
              <p>{i18n.faq.q6.p14}</p>,
              <StaticImage
                src="../assets/images/faq/beton.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q6.p15}</h3>
            {defaultLayout(
              <>
                <p>{i18n.faq.q6.p16}</p>
                <a
                  rel="noopener"
                  href="https://www.youtube.com/watch?v=9h7g4ymyJx8"
                  target="_blank"
                >
                  {i18n.faq.q6.p17}
                </a>
              </>,
              <StaticImage
                src="../assets/images/faq/deadman.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q6.p18}</h3>
            {defaultLayout(
              <p>{i18n.faq.q6.p19}</p>,
              <StaticImage
                src="../assets/images/faq/bois.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q6.p20}</h3>
            {defaultLayout(
              <p>{i18n.faq.q6.p21}</p>,
              <StaticImage
                src="../assets/images/faq/bolt.jpg"
                alt=""
                className="shrink-0"
              />
            )}
            <h3>{i18n.faq.q6.p22}</h3>
            {defaultLayout(
              <>
                <p>{i18n.faq.q6.p23}</p>
                <a
                  rel="noopener"
                  href="https://www.youtube.com/watch?v=T3sbZe9mbEo"
                  target="_blank"
                >
                  {i18n.faq.q6.p24}
                </a>
              </>,
              <StaticImage
                src="../assets/images/faq/slackrack.jpg"
                alt=""
                className="shrink-0"
              />
            )}
          </>
        ),
      },
      {
        question: i18n.faq.q7.title,
        answer: defaultLayout(
          <p>{i18n.faq.q7.p1}</p>,
          <StaticImage
            src="../assets/images/faq/hiver.jpg"
            alt=""
            className="shrink-0"
          />
        ),
      },
      {
        question: i18n.faq.q8.title,
        answer: <p>{i18n.faq.q8.p1}</p>,
      },
      {
        question: i18n.faq.q9.title,
        answer: <p>{i18n.faq.q9.p1}</p>,
      },
      {
        question: i18n.faq.q10.title,
        answer: <p>{i18n.faq.q10.p1}</p>,
      },
      {
        question: i18n.faq.q11.title,
        answer: <p>{i18n.faq.q11.p1}</p>,
      },
    ],
  }

  return (
    <>
      <Hero title={i18n.faq.title}>
        <StaticImage
          className="!absolute z-0 w-full h-full"
          src="../assets/images/banner_split.jpg"
          objectPosition="70% 50%"
          alt=""
          quality={95}
        />
      </Hero>
      <div className="my-12 out-wrapper">
        <h2 className="text-4xl font-bold">{i18n.faq.generaInfo}</h2>
        <Accordion faqs={questions.general} />
        <h2 className="mt-12 text-4xl font-semibold">
          {i18n.faq.technicalInfo}
        </h2>
        <Accordion faqs={questions.technical} />
      </div>
    </>
  )
}

export const query = graphql`
  query FAQQuery($lang: String) {
    # allMdx(filter: { frontmatter: { id: { eq: "terms-en" } } }) {
    legal: allMdx(
      filter: { frontmatter: { id: { eq: "legal" }, lang: { eq: $lang } } }
    ) {
      nodes {
        frontmatter {
          title
        }
        body
      }
    }
    safe: allMdx(
      filter: { frontmatter: { id: { eq: "safe" }, lang: { eq: $lang } } }
    ) {
      nodes {
        frontmatter {
          title
        }
        body
      }
    }
    classes: allMdx(
      filter: { frontmatter: { id: { eq: "classes" }, lang: { eq: $lang } } }
    ) {
      nodes {
        frontmatter {
          title
        }
        body
      }
    }
    important: allMdx(
      filter: { frontmatter: { id: { eq: "important" }, lang: { eq: $lang } } }
    ) {
      nodes {
        frontmatter {
          title
        }
        body
      }
    }
  }
`
